import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { connect } from 'react-redux'
import { getPacksByPackTaxonId, getPackTaxons } from '../../redux/actions/packs'
import Glider from 'react-glider'
import { scrollToDiv } from '../../utils/utils'
import Statements from '../Statements/Statements'
import Button from '../Button/Button'
import PackCard from './PackCard'
import DateRangeForm from '../DateRangeForm/DateRangeForm'
import { ResponsiveImage } from '../../typings/base'
import { getLatestProducts } from '../../redux/actions/products'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { prismicText } from '../Global/CMSText'
import dynamic from 'next/dynamic'
import Link from '../Link/Link'
import { RootState } from '../../redux/reducers/rootReducer'
import { GetMapDispatchTypes } from '../../interfaces/common/redux'
import { PrismicHomePageData } from '../../interfaces/common/prismicHomePage'
const HeroHomeImg = dynamic(() => import('./HeroHomeImg'))

const useStyles = makeStyles((theme) =>
  createStyles({
    color_white: {
      color: theme.palette.common.white,
    },
    invert: {
      filter: 'invert(1)',
    },
    root: {
      zIndex: 2,
      position: 'relative',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      minHeight: '80vh',
      justifyContent: 'center',
      textAlign: 'center',
      overflow: 'hidden',
      '&.filter': {
        '&::after': {
          zIndex: 1,
          position: 'absolute',
          content: "''",
          height: '100%',
          width: '100%',
          top: 0,
          bottom: 0,
          left: 0,
          opacity: 0.6,
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 72.15%, #000000 100%)',
        },
      },
      '&.center': {
        '& .layout': {
          [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            textAlign: 'center',
            '& .MuiGrid-grid-md-6': {
              maxWidth: '100%',
              flexBasis: '100%',
              '& +.MuiGrid-grid-md-6>div': {
                maxWidth: 680,
                margin: 'auto',
              },
            },
            '& .tagline, & .statements > .MuiGrid-root': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            '& .date_form, & .ctas': {
              justifyContent: 'center',
            },
          },
        },
      },
      '&.left': {
        '&.filter:after': {
          [theme.breakpoints.up('md')]: {
            left: 0,
            background:
              'linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.8) 42.42%, rgba(37, 24, 56, 0) 100%)',
          },
        },
        '& .MuiBox-root': {
          [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            textAlign: 'left',
            '& .date_form': {
              justifyContent: 'flex-start',
            },
          },
        },
      },
      '&.right': {
        '&.filter:after': {
          [theme.breakpoints.up('md')]: {
            height: '100%',
            left: 'auto',
            right: 0,
            background:
              'linear-gradient(-90deg, #000000 0%, rgba(0, 0, 0, 0.8) 42.42%, rgba(37, 24, 56, 0) 100%)',
          },
        },
        '& .layout': {
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
            textAlign: 'left',
            '& .date_form': {
              justifyContent: 'flex-start',
            },
          },
        },
      },
    },
    media: {
      zIndex: -100,
      position: 'absolute',
      overflow: 'hidden',
      left: 0,
      top: theme.sizes.headerHeight,
      right: 0,
      bottom: 0,
      width: '100%',
      height: `calc(100% - ${theme.sizes.headerHeight}px)`,
      objectFit: 'cover',
      backgroundColor: theme.palette.common.black,
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
    },
    mediaTransparent: {
      top: 0,
      height: '100%',
    },
    wrapper: {
      zIndex: 3,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.sizes.headerHeight,
      minHeight: `calc(100vh - ${theme.sizes.headerHeight}px)`,
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${theme.sizes.headerHeight * 2}px)`,
      },
    },
    wrapperTransparent: {
      paddingTop: theme.sizes.headerHeightTransparent,
      minHeight: `calc(100vh - ${theme.sizes.headerHeightTransparent}px)`,
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${theme.sizes.headerHeightTransparent * 2}px)`,
      },
    },
    box: {},
    box__primary: {},
    box__secondary: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
      },
    },
    box__ctas: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      '&>a': {
        display: 'inline-block',
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
          margin: 0,
          '&+a': {
            margin: theme.spacing(0, 2),
          },
        },
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      '& .paragraph-class': {
        margin: 0,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(30),
      },
    },
    tagline: {
      lineHeight: 1.2,
      fontSize: theme.typography.pxToRem(12),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(15),
        maxWidth: '75%',
        lineHeight: 'inherit',
      },
    },
    buttonToPack: {
      marginTop: theme.spacing(4),
      width: '100%',
      textAlign: 'center',
    },
    slide: {
      padding: theme.spacing(0, 5),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: '100vw',
        transform: 'translateX(-50%) translateY(0)',
        left: '50%',
        marginBottom: theme.spacing(2),
      },
      '& .MuiBox-root': {
        transition: theme.transitions.create('opacity'),
      },
    },
    slideArrow: {
      '& .glider-next': {
        right: -40,
      },
      '& .glider-prev': {
        left: -40,
      },
    },
    slideActive: {
      opacity: 1,
      '& .MuiBox-root': {
        opacity: 0.75,
        '&:hover': {
          opacity: 1,
        },
      },
    },
    slideInactive: {
      opacity: 1,
    },
    smallerButton: {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(1, 2),
      },
    },
    statementsMobile: {
      marginTop: theme.spacing(2),
    },
    date_form: {
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'center',
      margin: theme.spacing(3, 0, 8),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(8, 0, 3),
        padding: theme.spacing(2, 1.5),
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
      },
    },
    subnav: {
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: theme.spacing(1.5),
      //color: theme.palette.common.white,
      '& a': {
        color: 'currentColor',
        fontWeigth: 700,
        textDecoration: 'underline',
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    linkButton: {
      padding: 0,
      color: '#fff',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'underline',
      margin: 0,
      '&:hover': {
        background: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  packs: state.packs,
  products: state.products,
})

const mapDispatchToProps = {
  getPackTaxons: getPackTaxons,
  getPacksByPackTaxonId: getPacksByPackTaxonId,
  getLatestProducts: getLatestProducts,
}

type Tprops = ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps> & {
    children: React.ReactNode
    settings?: PrismicHomePageData
    text?: any
    t: any
    statements?: any
    isProduct: any
    thumbnail_ratio: any
    isEnableChooseStartingDate: boolean
  }

const HeroHome = ({
  settings,
  text,
  products,
  getLatestProducts,
  getPackTaxons,
  packs,
  t,
  isProduct,
  getPacksByPackTaxonId,
  statements,
  children,
  isEnableChooseStartingDate,
}: Tprops) => {
  const classes = useStyles()
  const theme = useTheme()
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktopXL = useMediaQuery(theme.breakpoints.up('xl'))
  const isSubscription =
    process.env.STORE_TYPE === 'subscription' ||
    process.env.STORE_TYPE === 'classic_subscription'
  const isPack = packs?.items?.length > 0
  const { trackEvent } = useMatomo()
  const textColor = settings?.hero_text_color
    ? settings.hero_text_color
    : '#ffffff'
  const button_to_packs = prismicText(settings?.cta_pack_label)
  const price_pack = prismicText(settings?.pack_price)
  const showStatements = settings?.show_statements
  const showPacks = settings?.show_featured_packs
  const customProducts = settings?.products
  const isCustomProducts = customProducts?.length > 0
  const mainImg: ResponsiveImage = settings?.mainimg
  const isVideoMobile = settings?.enable_background_video_mobile
  const video = settings?.background_video_file.url
  const videoMobile = settings?.background_video_mobile.url
  const ctas = settings?.hero_ctas
  const isTransparentMedia = settings?.header_style
    ? `hero__home-media--transparent ${classes.media} ${classes.mediaTransparent}`
    : `hero__home-media ${classes.media}`
  const isUseCarousel = settings?.use_carousel
  const thumbnail_ratio = text?.thumbnail_ratio

  const [activePacks, setActivePacks] = useState(false)

  const handleActivePack = () => {
    setActivePacks(true)
  }

  const handleDesActivePack = () => {
    setActivePacks(false)
  }

  useEffect(() => {
    async function getTaxons() {
      const taxon = await getPackTaxons()
      if (typeof taxon !== 'undefined' && taxon?.taxonPack?.length > 0) {
        getPacksByPackTaxonId(taxon?.taxonPack[0]?.id)
      }
    }

    if (isProduct) {
      getLatestProducts()
    } else {
      getTaxons()
    }
  }, [])

  let layoutOpt
  switch (settings?.hero_layout) {
    case 'Left':
      layoutOpt = 'left'
      break
    case 'Center':
      layoutOpt = 'center'
      break
    case 'Right':
      layoutOpt = 'right'
      break
    default:
      layoutOpt = 'left'
      break
  }

  const videoTag = (src: string) => {
    return !prefersReducedMotion ? (
      <video
        autoPlay
        loop
        muted
        playsInline
        className={isTransparentMedia}
        preload={'none'}
        poster={
          mainImg?.md?.url ? mainImg?.md?.url : '/images/video_placeholder.jpg'
        }
      >
        <source src={src} type="video/mp4" />
      </video>
    ) : (
      <HeroHomeImg
        src={mainImg}
        classes={isTransparentMedia}
        isMobile={isMobile}
        isDesktopXL={isDesktopXL}
      />
    )
  }

  return (
    <>
      <div
        className={`hero__home ${classes.root} ${
          settings?.background_filter ? 'filter' : 'no-filter'
        } ${layoutOpt}`}
      >
        <>
          {isVideoMobile && isMobile && videoTag(videoMobile)}
          {isVideoMobile && !isMobile && videoTag(video)}
          {!isVideoMobile && video && videoTag(video)}
          {!isVideoMobile && !video && (
            <HeroHomeImg
              src={mainImg}
              classes={isTransparentMedia}
              isMobile={isMobile}
              isDesktopXL={isDesktopXL}
            />
          )}
        </>
        <Container maxWidth="xl">
          {children}
          <Box
            id="hero__home__wrapper"
            className={
              settings?.header_style
                ? `wrapper ${classes.wrapper} ${classes.wrapperTransparent}`
                : `wrapper ${classes.wrapper}`
            }
          >
            <Grid container spacing={3} className="layout">
              <Grid item md={6} xs={12}>
                <div
                  className={clsx(classes.box, 'hero__home-box')}
                  style={{ color: textColor }}
                >
                  <div className={classes.box__primary} id="hero__box">
                    <Typography
                      variant="h1"
                      className={`title ${classes.title}`}
                      style={{ color: textColor }}
                      gutterBottom
                    >
                      <CMSText
                        data={settings?.maintitle}
                        defaultText={t('texts:homepage:maintitle')}
                      />
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`tagline ${classes.tagline}`}
                      component="div"
                      style={{ color: textColor }}
                    >
                      <CMSText
                        data={settings?.descriptiontext}
                        defaultText={t('texts:homepage:descriptiontext')}
                      />
                    </Typography>
                    {(!isSubscription || isEnableChooseStartingDate) && (
                      <div className={`date_form ${classes.date_form}`}>
                        <DateRangeForm isLanding={true} t={t} text={text} />
                      </div>
                    )}
                  </div>
                  {ctas &&
                    ctas.length > 0 &&
                    ctas[0]?.cta_link?.url !== undefined &&
                    ctas[0]?.cta_label.length !== 0 && (
                      <div className={`ctas ${classes.box__ctas}`}>
                        {ctas.map((cta, index) => (
                          <Link
                            key={index}
                            href={cta.cta_link.url ? cta.cta_link.url : '/'}
                          >
                            <a>
                              <Button
                                variant={
                                  cta.cta_style ? 'outlined' : 'contained'
                                }
                                color={cta.cta_color ? 'secondary' : 'primary'}
                                component="span"
                                onClick={() =>
                                  trackEvent({
                                    category: `HomePage`,
                                    href: cta?.cta_link?.url,
                                    action: `CTA n°${index} to page ${cta?.cta_link?.url}`,
                                  })
                                }
                              >
                                {prismicText(cta?.cta_label)}
                              </Button>
                            </a>
                          </Link>
                        ))}
                      </div>
                    )}
                  {showStatements &&
                    (isSubscription ? (
                      !isMobile && (
                        <div className={`statements ${classes.box__secondary}`}>
                          <Statements
                            statements={statements}
                            textColor={textColor}
                            t={t}
                          />
                        </div>
                      )
                    ) : (
                      <div
                        className={`statements ${classes.box__secondary}`}
                        style={{ color: textColor }}
                      >
                        <Statements
                          statements={statements}
                          textColor={textColor}
                          t={t}
                        />
                        <Typography className={classes.subnav}>
                          {prismicText(
                            statements?.data?.bottom_sentence,
                            t('texts:homepage:bottom_sentence')
                          )}{' '}
                          <Button
                            className={classes.linkButton}
                            variant={'text'}
                            onClick={() => scrollToDiv('more')}
                            style={{ color: textColor }}
                          >
                            {prismicText(
                              statements?.data?.link,
                              t('texts:homepage:link')
                            )}
                          </Button>
                        </Typography>
                      </div>
                    ))}
                </div>
              </Grid>
              {showPacks &&
                !isCustomProducts &&
                ((packs.loading === true &&
                  typeof packs?.items === 'undefined') ||
                (products.loading === true &&
                  typeof products?.latestProducts === 'undefined') ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <div
                        className={clsx(
                          classes.slide,
                          activePacks
                            ? classes.slideActive
                            : classes.slideInactive
                        )}
                        onMouseOver={handleActivePack}
                        onMouseOut={handleDesActivePack}
                      >
                        {!isMobile ? (
                          <Grid container spacing={3}>
                            {[0, 1, 2].map((item) => {
                              return (
                                <Grid item xs={4} key={item}>
                                  <PackCard isLoading />
                                </Grid>
                              )
                            })}
                          </Grid>
                        ) : (
                          isSubscription && (
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <PackCard isLoading />
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                      {isSubscription && !isProduct && (
                        <div className={classes.buttonToPack}>
                          <Link href={'/packs'}>
                            <a>
                              <Button
                                variant={'contained'}
                                color={'secondary'}
                                size={'medium'}
                                component="span"
                                endIcon={<KeyboardArrowRight />}
                                onClick={() =>
                                  trackEvent({
                                    category: `HomePage`,
                                    href: '/packs',
                                    action: `CTA Hero to /packs`,
                                  })
                                }
                              >
                                {button_to_packs ? button_to_packs : ''}
                              </Button>
                            </a>
                          </Link>
                        </div>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item md={6} xs={12}>
                    <div
                      className={clsx(
                        classes.slide,
                        activePacks
                          ? classes.slideActive
                          : classes.slideInactive
                      )}
                      onMouseOver={handleActivePack}
                      onMouseOut={handleDesActivePack}
                    >
                      {!isSubscription ? (
                        !isMobile && (
                          <Grid container spacing={3}>
                            {isPack && !isProduct ? (
                              <>
                                {packs?.items?.map((pack, index) => {
                                  if (index <= 2) {
                                    return (
                                      <Grid md={4} item key={index}>
                                        <PackCard
                                          pack={pack}
                                          thumbnail_ratio={thumbnail_ratio}
                                          price_pack={price_pack}
                                          isPack={true}
                                        />
                                      </Grid>
                                    )
                                  }
                                })}
                              </>
                            ) : (
                              <>
                                {products?.latestProducts?.length > 0 &&
                                  products?.latestProducts?.map(
                                    (pack, index) => {
                                      if (index <= 2) {
                                        return (
                                          <Grid md={4} item key={index}>
                                            <PackCard
                                              thumbnail_ratio={thumbnail_ratio}
                                              price_pack={price_pack}
                                              pack={pack}
                                            />
                                          </Grid>
                                        )
                                      }
                                    }
                                  )}
                              </>
                            )}
                          </Grid>
                        )
                      ) : isMobile ? (
                        <Glider
                          draggable
                          scrollLock
                          slidesToShow={1}
                          hasDots
                          scrollLockDelay={100}
                          duration={1}
                        >
                          {isPack
                            ? packs?.items?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <PackCard
                                      key={index}
                                      thumbnail_ratio={thumbnail_ratio}
                                      pack={pack}
                                      price_pack={price_pack}
                                      isPack
                                    />
                                  )
                                }
                              })
                            : products?.latestProducts?.length > 0 &&
                              products?.latestProducts?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <PackCard
                                      pack={pack}
                                      key={index}
                                      thumbnail_ratio={thumbnail_ratio}
                                      price_pack={price_pack}
                                    />
                                  )
                                }
                              })}
                        </Glider>
                      ) : (
                        <Grid container spacing={3}>
                          {isPack && !isProduct ? (
                            <>
                              {packs?.items?.map((pack, index) => {
                                if (index <= 2) {
                                  return (
                                    <Grid md={4} item key={index}>
                                      <PackCard pack={pack} isPack />
                                    </Grid>
                                  )
                                }
                              })}{' '}
                            </>
                          ) : (
                            <>
                              {products?.latestProducts?.length > 0 &&
                                products?.latestProducts?.map((pack, index) => {
                                  if (index <= 2) {
                                    return (
                                      <Grid md={4} item key={index}>
                                        <PackCard
                                          pack={pack}
                                          thumbnail_ratio={thumbnail_ratio}
                                        />
                                      </Grid>
                                    )
                                  }
                                })}
                            </>
                          )}
                        </Grid>
                      )}
                    </div>
                    {isSubscription
                      ? isPack && (
                          <div className={classes.buttonToPack}>
                            <Link href={'/packs'}>
                              <a>
                                <Button
                                  variant={'contained'}
                                  color={'secondary'}
                                  className={classes.smallerButton}
                                  component="span"
                                  endIcon={<KeyboardArrowRight />}
                                  onClick={() =>
                                    trackEvent({
                                      category: 'HomePage',
                                      href: '/packs',
                                      action: 'HeroHome to pack',
                                    })
                                  }
                                >
                                  {button_to_packs ? button_to_packs : ''}
                                </Button>
                              </a>
                            </Link>
                          </div>
                        )
                      : !isMobile &&
                        isPack &&
                        !isProduct && (
                          <div className={classes.buttonToPack}>
                            <Link href={'/packs'}>
                              <a>
                                <Button
                                  variant={'contained'}
                                  onClick={() =>
                                    trackEvent({
                                      category: 'HomePage',
                                      href: '/packs',
                                      action: 'HeroHome to pack',
                                    })
                                  }
                                  color={'secondary'}
                                  className={classes.smallerButton}
                                  component="span"
                                  endIcon={<KeyboardArrowRight />}
                                >
                                  {button_to_packs ? button_to_packs : ''}
                                </Button>
                              </a>
                            </Link>
                          </div>
                        )}
                    {!isMobile && isProduct && (
                      <div className={classes.buttonToPack}>
                        <Link href={'/shop'}>
                          <a>
                            <Button
                              variant={'contained'}
                              color={'secondary'}
                              onClick={() =>
                                trackEvent({
                                  category: 'HomePage',
                                  href: '/shop',
                                  action: 'HeroHome to shop',
                                })
                              }
                              className={classes.smallerButton}
                              component="span"
                              endIcon={<KeyboardArrowRight />}
                            >
                              {'Voir tous les produits'}
                            </Button>
                          </a>
                        </Link>
                      </div>
                    )}
                  </Grid>
                ))}
              {isCustomProducts && customProducts[0].desc.length > 0 && (
                <>
                  <Grid item md={6} xs={12}>
                    <div
                      className={clsx(
                        classes.slide,
                        activePacks
                          ? classes.slideActive
                          : classes.slideInactive
                      )}
                      onMouseOver={handleActivePack}
                      onMouseOut={handleDesActivePack}
                    >
                      {isMobile ? (
                        <>
                          <Glider
                            draggable
                            scrollLock
                            slidesToShow={1}
                            hasDots
                            scrollLockDelay={100}
                            duration={1}
                          >
                            {settings?.products?.map((pack, index) => {
                              return (
                                <PackCard
                                  key={index}
                                  thumbnail_ratio={thumbnail_ratio}
                                  price_pack={price_pack}
                                  pack={pack}
                                  isCustom
                                  isPack={false}
                                />
                              )
                            })}
                          </Glider>
                        </>
                      ) : isUseCarousel ? (
                        <Grid
                          container
                          spacing={3}
                          className={classes.slideArrow}
                        >
                          <Glider
                            hasArrows
                            draggable
                            scrollLock
                            slidesToShow={3}
                            iconLeft={<KeyboardArrowLeft />}
                            iconRight={<KeyboardArrowRight />}
                            scrollLockDelay={100}
                            duration={1}
                          >
                            {settings?.products?.map((pack, index) => {
                              return (
                                <Grid md={4} item key={index}>
                                  <p>{activePacks}</p>
                                  <Box key={index} p={1.5}>
                                    <PackCard
                                      price_pack={price_pack}
                                      pack={pack}
                                      isCustom
                                      isPack={false}
                                      thumbnail_ratio={thumbnail_ratio}
                                    />
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Glider>
                        </Grid>
                      ) : (
                        <Grid container spacing={3}>
                          {settings?.products?.map((pack, index) => {
                            if (index <= 2) {
                              return (
                                <Grid md={4} item key={index}>
                                  <PackCard
                                    thumbnail_ratio={thumbnail_ratio}
                                    text={text}
                                    price_pack={price_pack}
                                    pack={pack}
                                    isCustom
                                    isPack={false}
                                  />
                                </Grid>
                              )
                            }
                          })}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Container>
      </div>
      {isSubscription && showStatements && isMobile && (
        <div
          className={`statements ${
            textColor === '#ffffff' ? classes.invert : ''
          } ${classes.statementsMobile} ${classes.box__secondary}`}
        >
          <Statements statements={statements} t={t} textColor={textColor} />
        </div>
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroHome)
